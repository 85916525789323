/** This template is used by /scripts/prebuild.js
 * to create ci stage specific environment
 */
export const environment = {
  production: true,
  hmr: false,
  TENANT_APP_PATH: '/df/',
  PUBLIC_APP_PATH: '/app/',
  API_URL: 'staging.dailyfrenzy.com',
  CURRENCY: 'USD',
  CI_COMMIT_SHA: 'c4cdcba61a9e4963c6df378be593c78fa84e001a',
};
